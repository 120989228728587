@use 'fonts'
@use 'standard-block'
@import 'colours'
@import 'shadows'

#extraODPnav
    background: transparent
    position: relative
    margin-top: 2rem
    width: 100%
    background-color: #000000 !important
    background: linear-gradient(to left, #003349 0%, transparent 100%)


    .btn
        text-align: left
        color: $esa-lightgrey !important
        text-transform: none
        background-color: red
        border: none
        width: max-content
        border-radius: 2px
        letter-spacing: 0.25rem
        transition: none
        font-size: 2rem
        font-family: NotesESAbold, sans-serif
        margin-bottom: 1rem
        margin-left: 0
        margin-right: 0
        padding-left: 0
        padding-right: 0

        &:hover
            color: $esa-teal !important
            background-color: transparent !important

    #navbarODPInner
        margin-top: 3rem
        margin-bottom: 0

        a
            font-size: 20px
            border-bottom: 0
            box-shadow: none
        
        .nav
            border-bottom: none

        a:hover,a:focus
            outline: none
            text-decoration: none
                
        .nav-tabs .nav-link,
        .nav-tabs .nav-item.show .nav-link
            color: $lightgrey
            border: none
            background-color: transparent
        .nav-tabs .nav-link.active
            color: $lightgrey
            border: none
            background-color: transparent
            border-bottom: solid 5px $lightgrey

        .nav-tabs .nav-link:hover
            color: $esa-teal55
            transform: none

        @media screen and (max-width: 768px)
            .nav-sm-column
                margin-top: 3rem
                height: auto
                flex-direction: column !important

    #nav-tab-ODP
        margin-bottom: 10rem
        border-bottom: none

// TODO: Should the HTML tags used here be moved in to a dedicated template, rather than duplicated?

// TODO: Decide whether to switch to using the class. Using an id means that all pages have to use the same id,
// which prevents us from using the id to identify genuinely unique entities. That would be useful for page-specific
// styles.
#main-standard,
.main-standard,
#news-content,
.news-content
    --bs-bg-opacity: 1
    // background: linear-gradient(to top right, #000000, #1e191d, #2e2c39, #354456, #335e6f)
    // background: linear-gradient(to top right, #000000, #000000, #000000, $deepspace-blue, $deepspace-blue)
    background: $gradient-background

    color: #ffffff
    padding-top: 3rem
    
    background-color: #fff
    // font-size: 1.5rem
    flex: 1

    figcaption
        margin-bottom: 3rem
        margin-top: 1rem
        font-size: 2rem
        color: $black50

    figure
        margin-top: 3rem

    #img-container
        figure
            margin-top: 1rem
        figcaption
            margin-bottom: 0
            font-size: 1.2rem
            font-style: italic


// TODO: Should this be a global definition? Removed completely in favour of other styles? Inside the above block?
.content
    padding-top: 3rem
    padding-bottom: 3rem
    flex: 1
    .lead-paragraph
        // font-size: 28px
        font-size: 2.75rem
        font-weight: 700
        // line-height: 32px
        line-height: 3rem
        margin-bottom: 8rem

.tab-content
    .tab-pane
        background-color: #000000 !important
        background: linear-gradient(to left, #003349 0%, transparent 100%)