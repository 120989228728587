@import 'colours'
@import '~include-media/dist/_include-media.scss'

#standard-block,
.standard-block,
.standard-block.content
    width: 100%
    max-width: 1250px

    margin-left: auto
    margin-right: auto

    // This is here because we used to have many nested divs (main-standard > standard-content > .row.content > standard-block)
    // and I removed several layers which didn't seem to do anything except double-pad. Since two had class .content,
    // we need to add the padding the removed blocks had to keep things the same during refactoring.
    padding-bottom: 6rem
    // Originally this padding was from being a Bootstrap column - but it may have been incorrectly used, because the
    // row is meant to have -15px padding and that wasn't working.
    padding-left: 15px
    padding-right: 15px

    // TODO: This is the version of this from standard-cards.sass, pre-dedup. standard-body had fixed 6rem. Check.
    @include media('>tablet')
        padding-top: 6rem
    @include media('<=tablet')
        padding-top: 1rem

    .blog-info
        .avatar
            img
                height: 75px
                width: 75px
                margin: 15px

        .user-name
            h3
                font-size: 1.75rem
                margin-top: 0.75rem
                margin-bottom: 0.75rem

        .badge-category
            background-color: $esa-teal

        .badge-category:hover
            background-color: $esa-teal55

        .badge-tag
            background-color: $esa-teal

        .badge-tag:hover
            background-color: $esa-teal55

    .standard-text
        p:first-of-type
            font-weight: bold

    .addthis_inline_share_toolbox
        margin-top: 3rem
